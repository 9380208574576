<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <base-material-card color="indigo">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $t('users') }}
            </div>
          </template>
        </base-material-card>
        <devices-table v-if="!pairable" :device="$route.params.slug" />

        <v-card v-else>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="5">
                <div class="d-flex flex-row align-baseline">
                  <v-autocomplete
                    v-model="search"
                    :items="availableUsers"
                    :item-text="availableUserText"
                    item-value="email"
                    :label="$t('search')"
                    disabled
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="w-100 d-flex flex-row flex-nowrap justify-space-between"
                      >
                        <span>{{ item.name }}</span>
                        <span>-</span>
                        <span>{{ item.email }}</span>
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-btn color="indigo" class="ms-6" disabled @click="addUser">
                    {{ $phraser.set('add user') }}
                  </v-btn>
                </div>
              </v-col>
              <!-- <v-divider class="my-4" /> -->
            </v-row>
          </v-card-title>
          <v-form ref="form" @submit.prevent="submit">
            <v-card-text v-if="pairings">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>{{ $phraser.set('users_name') }}</th>
                      <th>{{ $phraser.set('email') }}</th>
                      <th>{{ $phraser.set("device's name") }}</th>
                      <th>{{ $phraser.set('visibility') }}</th>
                      <th>{{ $phraser.set('delete') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="pairings.length">
                    <tr
                      v-for="(pair, index) in pairingsForm"
                      :key="index"
                      :class="pair.deleted ? 'red lighten-4' : ''"
                    >
                      <td
                        class="clickable"
                        @click="userDialog = pair.user.email"
                      >
                        {{ pair.user ? pair.user.name : '' }}
                      </td>
                      <td
                        class="clickable"
                        @click="userDialog = pair.user.email"
                      >
                        {{ pair.user ? pair.user.email : '' }}
                      </td>
                      <td>
                        <base-smart-input
                          :model.sync="pair.name"
                          min="2"
                          max="25"
                          required
                          disabled
                        />
                      </td>
                      <td>
                        <v-checkbox
                          v-model="pair.visibility"
                          :on-icon="'mdi-eye'"
                          :off-icon="'mdi-eye-off-outline'"
                          disabled
                        />
                        <!-- :disabled="pair.deleted" -->
                      </td>
                      <td>
                        <v-btn
                          icon
                          color="red"
                          large
                          disabled
                          @click="deleteRelation(index)"
                        >
                          <v-icon>
                            {{ pair.deleted ? 'mdi-delete-off' : 'mdi-delete' }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="d-flex flex-row justify-space-between mt-6">
              <v-btn
                color="success"
                :loading="loading"
                disabled
                @click="submit"
              >
                {{ $t('save') }}
              </v-btn>
              <h1 class="text-center primary--text display-4">
                בקרוב...
              </h1>
              <v-btn color="grey" outlined @click="setForm">
                {{ $t('cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <user-dialog :email="userDialog" />
  </v-container>
</template>

<script>
export default {
  name: 'Users',
  components: {
    DevicesTable: () => import('@/views/dashboard/pages/users/users'),
    UserDialog: () => import('@/views/dashboard/pages/users/Dialog'),
  },

  data: () => ({
    loading: false,
    search: '',
    pairingsForm: [],
    userDialog: '',
  }),

  computed: {
    pairable () {
      return this.$store.getters['user/permissions'].pairing
    },
    device () {
      return this.$store.getters['devices/getBySlug'](this.$route.params.slug)
    },
    pairings () {
      const deviceUsers = this.$store.getters['users/usersByDevice'](
        this.device.identifier,
      )
      let pairings = [...deviceUsers]
      pairings = pairings.map(p => {
        p.deleted = false
        return p
      })
      return pairings
    },
    availableUsers () {
      const copyForm = [...this.pairingsForm]
      const emails = copyForm.map(p => p.user.email)
      return this.$store.getters['users/users'].filter(user => {
        return !emails.includes(user.email)
      })
      // return []
    },
  },

  watch: {
    // pairingsForm: {
    //   handler (pairings, old) {
    //     console.log('pairingsForm changed')
    //   },
    //   deep: true,
    // },
    pairings: {
      handler (pairings, old) {
        this.setForm()
      },
      deep: true,
    },
  },

  mounted () {
    this.setForm()
  },

  methods: {
    setForm () {
      this.pairingsForm = JSON.parse(JSON.stringify(this.pairings)).map(p => {
        p.visibility = !!p.visibility
        return p
      })
    },
    deleteRelation (index) {
      const arr = this.pairingsForm
      this.pairingsForm = []
      if (typeof arr[index].deleted === 'boolean') {
        arr[index].deleted = !arr[index].deleted
        this.pairingsForm = arr
      } else {
        this.pairingsForm = arr.filter((value, key) => key !== index)
      }
    },
    availableUserText (user) {
      return `${user.name} - ${user.email}`
    },
    addUser () {
      if (this.search) {
        const user = this.availableUsers.filter(p => p.email === this.search)[0]
        if (user) {
          const obj = {
            device: this.device.identifier,
            name: this.device.identifier,
            user: user,
            user_email: user.email,
            visibility: 1,
          }
          this.pairingsForm.push(obj)
          this.search = null
        }
      }
    },
    async submit () {
      if (this.$refs.form.validate() && !this.loading) {
        // this.loading = true
        // const list = this.pairingsForm
        //   .filter(p => !p.deleted)
        //   .map(p => {
        //     return {
        //       email: p.user_email,
        //       visibility: p.visibility,
        //       name: p.name,
        //     }
        //   })
        // await this.$store.dispatch('devices/editDeviceUsers', [
        //   this.$route.params.slug,
        //   list,
        // ])
        // this.pairingsForm = JSON.parse(JSON.stringify(this.pairings)).map(
        //   pair => {
        //     pair.deleted = false
        //     return pair
        //   },
        // )
        // this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.deleted
  background-color: #ffffff
</style>
